<template>
  <b-card class="pageFont">

    <div class="mb-2">
      <b-row
        class="no-gutters"
      >
        <b-col
          md="8"
          class="p-0"
        >
          <b-col
            cols="12"
            class="p-0"
          >
            <span class="pageTitle">Outros produtos SESI</span>
          </b-col>
          <b-col
            cols="12"
            class="p-0"
          >
            <span class="pageSubtitle">Acesse aqui as outras soluções que o SESI oferece para você e sua empresa</span>
          </b-col>
        </b-col>
        <b-col
          md="4"
          class="text-left mt-2 text-md-right mt-md-0"
        >
          <b-button
            type="button"
            variant="outline-primary"
            @click.prevent="redirectPageCreateProduct"
          >
            <feather-icon
              icon="PlusCircleIcon"
              class="mr-1"
            />
              Cadastrar Novo Produto
          </b-button>
        </b-col>
      </b-row>
    </div>
    <hr class="invoice-spacing">
    <b-overlay
      :show="loading"
      rounded="lg"
      opacity="0.6"
    >
      <b-col
        cols="12"
        class="p-0 mb-1 d-flex justify-content-end"
      >
        <div class="d-flex justify-content-center align-items-center min-w-200px">
            <span class="mr-1" for="search-input">Buscar</span>
            <b-form-input
                id="search-input"
                placeholder="Nome do produto"
                v-model="searchableText"
                @input="searchFilteredProduct"
                v-mask="('X'.repeat(255))"
            />
        </div>
      </b-col>
      <div class="table-responsive">
        <table
          class="table b-table table-hover border tableWidth"
          role="table"
          aria-describedby="productsListTable"
        >
          <thead>
            <tr class="header-line">
              <th class="moveTableIcon">
                <img
                  :src="moveTableIcon"
                  alt="moveTableIcon"
                  width="32"
                  height="32"
                />
              </th>
              <th class="productLineAndColumn">PRODUTO</th>
              <th class="widthCenter">CRIADO EM</th>
              <th class="widthCenter">ÚLTIMA ALTERAÇÃO</th>
              <th class="widthCenter">STATUS</th>
              <th class="widthCenter">AÇÕES</th>
            </tr>
          </thead>
          <draggable
            v-model="draggableProductsList"
            @change="updateNewProductsList"
            :options="{disabled : !isDraggable}"
            tag="tbody"
          >
            <tr
              v-for="(product, key) in draggableProductsList"
              :key="key"
              :class="!isDraggable ? 'not-clickable' : null"
            >
              <td class="chevronUpAndDownIcon">
                <img
                  :src="chevronUpAndDownIcon"
                  alt="chevronUpAndDownIcon"
                  width="24"
                  height="24"
                />
              </td>
              <td class="productLineAndColumn">
                {{ product.nome }}
              </td>
              <td class="widthCenter">
                {{ transformDateTime(product.criacao) }}
              </td>
              <td class="widthCenter">
                {{ product.alteracao ? transformDateTime(product.alteracao) : '-' }}
              </td>
              <td class="widthCenter">
                <b-form-checkbox
                  :id="'check-ativo-'+product.relevancia"
                  v-model="product.ativo"
                  class="custom-control-success"
                  name="check-button"
                  @change="showStatusModal($event, product)"
                  switch
              >
                  <span class="switch-icon-left">
                      <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                      <feather-icon icon="XIcon" />
                  </span>
              </b-form-checkbox>
              </td>
              <td class="widthCenter">
                <b-button
                  variant="outline-primary"
                  class="btn-icon bt mr-1"
                  @click.prevent="showEditModal(product)"
                >
                  <feather-icon
                    class="iconColor"
                    icon="EditIcon"
                  />
                </b-button>
                <b-button
                  variant="outline-primary"
                  class="btn-icon bt"
                  @click.prevent="showDeleteModal(product)"
                >
                  <feather-icon
                    class="iconColor"
                    icon="Trash2Icon"
                  />
                </b-button>
              </td>
            </tr>
          </draggable>
        </table>
      </div>
    </b-overlay>
  </b-card>
</template>

<script>
import { BCard, BCol, BRow, BButton, BLink, BFormInput, BFormCheckbox, BOverlay } from 'bootstrap-vue'
import draggable from "vuedraggable";
import {
  GetDraggableProductsList,
  SetDraggableProductsList,
  GetFilterListProducts,
  SetFilterListProducts
} from '../Services/outrosProdutosHelper';
import moveTableIcon from "@/assets/custom-icons/moveTable.svg";
import chevronUpAndDownIcon from "@/assets/custom-icons/chevronUpAndDown.svg";
import moment from 'moment'
import { modalGenericModel } from '@/libs/sweetalerts'


export default {
  components: {
    BCard, BCol, BRow, BButton, BLink, BFormInput, BFormCheckbox, BOverlay,
    draggable,
  },

  setup() {
    return {
      moveTableIcon,
      chevronUpAndDownIcon
    };
  },

  computed: {
    draggableProductsList: {
      get() {
        return GetDraggableProductsList();
      },
      async set(value) {
        SetDraggableProductsList(value);
      }
    }
  },

  data() {
      return {
        searchableText: '',
        timeID: null,
        modal: {
          modalShow: false,
          product: null
        },
        loading: true,
        isDraggable: true
      }
  },

  async mounted() {
    this.searchableText = GetFilterListProducts();
    await this.getProductsList();
  },

  methods: {
    getProductsList(){
      this.loading = true;
      let params = null;
      this.isDraggable = true;
      if(this.searchableText.length >= 2){
        params = {
          pesquisa: this.searchableText
        };
        this.isDraggable = false;
      }
      this.$http.get(this.$api.OutrosProdutos.getAndSaveProduct, { params: params})
      .then((response) => {
        SetDraggableProductsList(response.data);
        this.loading = false;
      }).catch(() => {
        this.showGenericErrorModal()
      });
    },

    updateNewProductsList(event){
      let params = {
        "idOrdenacaoAntigo": event.moved.oldIndex,
        "idOrdenacaoNovo": event.moved.newIndex
      }
      this.$http.put(this.$api.OutrosProdutos.changeProductRelevance, params)
      .then()
      .catch(() => {
        this.showGenericErrorModal()
      });
    },

    searchFilteredProduct(){
      if(this.searchableText.length >= 2 || this.searchableText.length == 0) {
        clearTimeout(this.timeID);
        this.timeID = setTimeout(() => {
            SetFilterListProducts(this.searchableText);
            this.getProductsList();
        }, 500);
      }
    },
    redirectPageCreateProduct(){
      this.$router.push({ name: 'usuario-sesi-inserir-outros-produtos' });
    },

    showEditModal(product){
      this.$router.push({ name: 'usuario-sesi-editar-outros-produtos', params: { product: product , isEditMode: true} });
    },

    transformDateTime(dateTime){
      return moment.utc(dateTime).format('DD/MM/YY');
    },

    showGenericErrorModal(){
      modalGenericModel(
        'Ocorreu um erro!',
        'Ocorreu um erro de conexão com nossa base de dados. Por favor, tente novamente mais tarde ou contate nosso suporte.',
        require('@/assets/custom-icons/cora-icons/error.png'),
        'Ok'
      ).then(() => {
        this.$router.push(
          {
            name: 'bem-vindo'
          }
        );
      });
    },

    showDeleteModal(product){
      modalGenericModel(
        'Tem certeza?',
        'Não será possível desfazer essa ação depois',
        require('@/assets/custom-icons/cora-icons/error.png'),
        'Salvar',
        'Descartar'
      ).then((result) => {
        if(result.isConfirmed){
          this.deleteProduct(product);
        }
      })
    },

    async deleteProduct(product){
      this.loading = true;
      await this.$http.delete(this.$api.OutrosProdutos.getAndDeleteAndChangeStateUniqueProduct(product.id_produto))
      .then(() => {
        modalGenericModel(
          'Sucesso!',
          'O produto foi excluído com sucesso!',
          require('@/assets/custom-icons/cora-icons/success.png'),
          'Ok'
        ).then(async () => {
          await this.getProductsList();
          this.loading = false;
        });
      })
      .catch(() => {
        modalGenericModel(
          'Ocorreu um erro!',
          'Ocorreu um erro de conexão com nossa base de dados. Por favor, tente novamente mais tarde ou contate nosso suporte.',
          require('@/assets/custom-icons/cora-icons/error.png'),
          'Ok'
        ).then(() => {
          this.$router.push(
            {
              name: 'bem-vindo'
            }
          );
        });
      });
    },

    showStatusModal(action, product){
      modalGenericModel(
        action ? 'Tem certeza que deseja ativar?'
        : 'Tem certeza que deseja inativar?',
        action ?
        'Este produto voltará a ser exibido para os usuários na tela de Outros produtos' :
        'Este produto não será mais exibido para os usuários na tela de Outros produtos',
        require('@/assets/custom-icons/cora-icons/alert.png'),
         action ?
         'Sim, ativar' :
         'Sim, inativar',
        'Cancelar'
      ).then((result) => {
        if(result.isConfirmed){
          this.changeState(product);
        }
      })
    },

    changeState(product){
      this.$http.patch(this.$api.OutrosProdutos.getAndDeleteAndChangeStateUniqueProduct(product.id_produto))
      .then()
      .catch(() => {
        this.showGenericErrorModal()
      });
    }
  }
}
</script>

<style scoped>
  .pageFont{
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
  }
  .pageTitle{
    font-weight: 500;
    font-size: 28px;
    line-height: 34px;
    color: #5E5873;
  }
  .pageSubtitle{
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #5E5873;
  }

  th{
    text-align: left;
  }

  .moveTableIcon {
    padding: 2px 0 0 5px;
  }

  .chevronUpAndDownIcon {
    padding-left: 8px;
  }

  .productLineAndColumn {
    padding-left: 0;
    font-weight: 600;
  }

  .tableWidth{
    min-width: 850px;
  }

  .widthCenter{
    text-align: center;
  }

  .bt{
    border: 1px solid #2772c0 !important;
  }
  .iconColor{
    color: #2772c0;
  }
  .not-clickable {
    cursor: default;
  }
</style>
