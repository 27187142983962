import $store from '@/store';

export function GetDraggableProductsList() {
  return $store.getters["outrosProdutosState/getProductsList"];
}

export const SetDraggableProductsList = data => {
  $store.dispatch("outrosProdutosState/setProductsList", data);
}

export function GetFilterListProducts() {
  return $store.getters["outrosProdutosState/getProductsListFilter"];
}

export const SetFilterListProducts = data => {
  $store.dispatch("outrosProdutosState/setProductsListFilter", data);
}
